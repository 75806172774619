<template>
  <div>
    {{ $t('message.preview') }}:
  <div id="marquee" class="marquee">
    <span class="flix-html-strong flix-text-success">
      {{ dates }}
    </span>
  </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    weekday: Array,
    nextAppointments: [Number, String],
    pause: Array
  },
  data () {
    return {
      start: false,
      end: false,
      dates: false
    }
  },
  methods: {
    getDateLength () {
      if (isNaN(this.weekday[2])) {
        return 60
      }
      return this.weekday[2] * 1
    },
    getDates () {
      var r = []
      for (var i = 0; i <= 30; i++) {
        var s = new Date(this.start.getTime())
        s.setMinutes((s.getMinutes() + (i * this.nextAppointments)))
        var end = new Date(s.getTime())
        end.setMinutes(end.getMinutes() + this.getDateLength())
        end.setSeconds(0)
        if (end.getTime() > this.end.getTime()) {
          r.reverse()
          this.dates = r.join(' –––– ')
          return false
        }
        r[i] = ''

        if (this.checkPause(s, end)) {
          r[i] += 'Pause'
          continue
        }

        r[i] += this.$getNullsBefore(s.getHours()) + ':' + this.$getNullsBefore(s.getMinutes())
        r[i] += ' – '
        r[i] += this.$getNullsBefore(end.getHours()) + ':' + this.$getNullsBefore(end.getMinutes())
        r[i] += ' ' + this.$t('message.oClock')
      }
      r.reverse()
      this.dates = ' ... ' + r.join(' –––– ')
    },
    checkPause (s, e) {
      var error = false
      if (!this.pause.length) {
        return false
      }
      JSON.parse(JSON.stringify(this.pause)).forEach(function (p) {
        p[0] = p[0].split(':')
        p[1] = p[1].split(':')
        var start = new Date()
        start.setHours(p[0][0] * 1)
        start.setMinutes(p[0][1] * 1 + 1)
        start.setSeconds(0)
        var end = new Date()
        end.setHours(p[1][0] * 1)
        end.setMinutes(p[1][1] * 1 - 1)
        end.setSeconds(0)
        if (e.getTime() > start.getTime() && s.getTime() <= end.getTime()) {
          error = true
        }
      })
      return error
    },
    getStartTime () {
      var start = this.weekday[0]
      start = start.split(':')
      var date = new Date()
      date.setHours(start[0] * 1)
      date.setMinutes(start[1] * 1)
      date.setSeconds(0)
      this.start = date
    },
    getEndTime () {
      var end = this.weekday[1]
      end = end.split(':')
      var date = new Date()
      date.setHours(end[0] * 1)
      date.setMinutes(end[1] * 1)
      date.setSeconds(0)
      this.end = date
    }
  },
  mounted () {
    this.getStartTime()
    this.getEndTime()
    this.getDates()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .marquee
    max-width: 100%
    white-space: nowrap
    overflow: hidden

  .marquee span
    display: inline-block
    padding-left: 100%
    text-align: right
    animation: marquee 20s linear infinite reverse

  .marquee span:hover
    animation-play-state: paused

  @keyframes marquee
    0%
      transform: translateX(0)
    100%
      transform: translateX(-100%)
</style>
